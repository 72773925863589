import React, { Fragment, Component } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

// Commom Components
import PageTitle from "../../Common/PageTitle";
import Spinner from "../../Common/Spinner";
// Services
import UserService from "../../../services/UserService";
import commonUtiles from "../../../services/CommonUtiles";
import ConfirmationModal from "../../Common/ConfirmationModal";
import AuthService from "../../../services/AuthService";

// UserList Component
class UserList extends Component {
  constructor() {
    super();
    this.state = {
      users: [],
      loading: false,
      showReset: false,
      searchVal: "",
      filterVal: "",
      pageSize: commonUtiles.getPageSizes()[1],
      isPrevBtnActive: "disabled",
      isNextBtnActive: "",
      pageToken: "",
      preTokendata: [],
      nextPageToken: "",
      sort: {
        column: "email",
        direction: "desc",
      },
      showCnfMsg: false,
      statusProp: {},
      changing: false,
      userEmail: "",
    };
  }

  componentDidMount() {
    const { pageToken, searchVal } = this.state;
    this.getUsers(pageToken, searchVal);
    this.getUserEmail();
  }

  getUserEmail = () =>
    setTimeout(() => {
      this.setState({ userEmail: AuthService.getUserName() });
    }, 1000);

  // Function uses to filter users' data
  handleSearch = () => {
    const { searchVal, pageToken } = this.state;
    const filterVal = searchVal;
    this.setState({ filterVal });
    if (filterVal) {
      this.setState({ isPrevBtnActive: "disabled" });
      this.setState({ preTokendata: [] });
      this.getUsers(pageToken, filterVal);
      this.setState({ showReset: true });
    }
  };

  // Method use to get Users' data
  getUsers(pageToken, searchVal) {
    const { pageSize, sort } = this.state;
    this.setState({ loading: true });
    UserService.getUsers(pageSize.value, pageToken, searchVal)
      .then((response) => {
        this.setState({ loading: false });
        this.onSort(sort.column, response.data.users, true);
        if (response.data.PaginationToken) {
          this.setState({ nextPageToken: response.data.PaginationToken });
          this.setState({ isNextBtnActive: "" });
        } else {
          this.setState({ isNextBtnActive: "disabled" });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  // Enter key handler
  onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      this.handleSearch();
    }
  };

  // Function uses for clear filter data
  onResetHandler = (e) => {
    e.preventDefault();
    this.onReset();
  };

  // Function uses for clear filter data
  onReset = () => {
    const { filterVal } = this.state;
    this.setState({ searchVal: "" });
    this.setState({ preTokendata: [] });
    this.setState({ pageToken: "" });
    this.setState({ showReset: false });

    if (filterVal) {
      this.setState({ isPrevBtnActive: "disabled" });
      this.getUsers("", "");
    }
    this.setState({ filterVal: "" });
  };

  // Function use for sorting table data in ascending and descending order.
  onSort = (column, data, listing) => {
    const { sort } = this.state;
    const direction = !listing ? (sort.direction === "asc" ? "desc" : "asc") : sort.direction;
    const allEqual = data.every((item) => data[0][column] === item[column]);
    let sortedData;
    if (allEqual) {
      sortedData = data.reverse();
    } else {
      sortedData = data.sort((a, b) => commonUtiles.compareValues(a, b, column, direction));
      if (direction === "desc") {
        sortedData.reverse();
      }
    }
    this.setState({
      users: sortedData,
      sort: {
        column,
        direction,
      },
    });
  };

  // Set Arrow direction as per sorting order
  setArrow = (column) => {
    const { sort } = this.state;
    return commonUtiles.setArrow(sort, column);
  };

  // function invoke on Next button click
  btnNextClick = (e) => {
    e.preventDefault();
    const { preTokendata, nextPageToken, filterVal } = this.state;
    preTokendata.push(nextPageToken);
    this.setState({ isPrevBtnActive: "" });
    this.getUsers(nextPageToken, filterVal);
  };

  // function invoke when Previous button click
  btnPrevClick = (e) => {
    e.preventDefault();
    const { preTokendata, filterVal } = this.state;
    if (preTokendata.length > 1) {
      this.getUsers(preTokendata[preTokendata.length - 2], filterVal);
      preTokendata.pop();
    } else {
      preTokendata.pop();
      this.getUsers("", filterVal);
      this.setState({ isPrevBtnActive: "disabled" });
    }
    this.setState({ isNextBtnActive: "" });
  };

  // function use to set filter value
  updateInputValue = (evt) => {
    const { filterVal } = this.state;
    this.setState({ searchVal: evt.target.value });
    if (evt.target.value) {
      this.setState({ showReset: true });
    } else {
      this.setState({ showReset: false });
      if (filterVal) {
        this.onReset();
      }
    }
  };

  openConfirmationModal = (statusProp) => {
    this.setState({ showCnfMsg: true, statusProp });
  };

  closeConfirmationModal = (data) => {
    const { statusProp } = this.state;
    if (data) {
      this.updateUserStatus(statusProp.email, !statusProp.userEnable);
    } else {
      this.setState({ statusProp: {}, showCnfMsg: false });
    }
  };

  // function use for update user status (enable/disable)
  updateUserStatus = (email, status) => {
    this.setState({ changing: true });
    UserService.statusUserservice(email, status)
      .then(() => {
        commonUtiles.displayNotification(`User is ${status ? "enabled" : "disabled"} successfully`, "success");
        this.setState({ searchVal: "" });
        this.getUsers("", "");
        this.setState({ statusProp: {}, showCnfMsg: false, changing: false });
      })
      .catch((error) => {
        commonUtiles.displayErrorMessage(error);
        this.setState({ statusProp: {}, showCnfMsg: false, changing: false });
      });
  };

  getModel = () => {
    const { showCnfMsg, changing, statusProp } = this.state;
    return (
      <Modal
        className="confirmModal"
        backdrop="static"
        tabindex="-1"
        show={showCnfMsg}
        onHide={this.closeConfirmationModal}
        centered
      >
        <ConfirmationModal
          closeConfirmationModal={this.closeConfirmationModal}
          message={`Are you sure, you want to ${statusProp.userEnable ? "Disable" : "Enable"} the user?`}
          changing={changing}
        />
      </Modal>
    );
  };

  // handle onChange event of the dropdown
  pageChange = (e) => {
    const { filterVal } = this.state;

    this.setState({ pageSize: e, preTokendata: [], pageToken: "", isPrevBtnActive: "disabled" }, () =>
      this.getUsers("", filterVal)
    );
  };

  render() {
    const {
      users,
      pageSize,
      isPrevBtnActive,
      isNextBtnActive,
      loading,
      showReset,
      searchVal,
      showCnfMsg,
      userEmail,
    } = this.state;

    // limit userlist to 10 records.
    const userList = users.slice(0, pageSize.value);
    return (
      <>
        {showCnfMsg ? this.getModel() : null}
        <PageTitle title="Users" class="" />
        <div className="card">
          <div className="card-header bg-gray-dark">
            <div className="row  flex-grow-1 d-flex">
              <div className="col-auto mr-auto d-flex">
                <div className="input-group mr-1">
                  <div className="input-icon">
                    <input
                      type="text"
                      className="form-control mg-rt-3 user-input search-input"
                      onKeyDown={this.onKeyDownHandler}
                      value={searchVal}
                      onChange={this.updateInputValue}
                      placeholder="Search for Email Address"
                      autoComplete="off"
                    />
                    {showReset ? (
                      <a
                        href="#"
                        aria-label="link"
                        onClick={this.onResetHandler}
                        className="input-icon-addon custom-i-addon"
                      >
                        <i className="fa fa-times" />
                      </a>
                    ) : null}
                  </div>

                  <span className="input-group-append">
                    <button className="btn btn-red" aria-label="button" type="button" onClick={this.handleSearch}>
                      <i className="fa fa-search" />
                    </button>
                  </span>
                </div>
              </div>
              <div className="card-options m-0">
                <Link to="/users/createuser" class="btn btn-red">
                  Create User
                </Link>
              </div>
            </div>
          </div>
          <div className="table-min-height">
            <table className="table table-outline table-vcenter card-table">
              <thead className="text-nowrap">
                <tr>
                  <th onClick={() => this.onSort("email", users)} className="curser-pointer width-30 header-sticky">
                    Email Address
                    <span className={this.setArrow("email")} />
                  </th>
                  <th onClick={() => this.onSort("name", users)} className="curser-pointer width-15 header-sticky">
                    First Name
                    <span className={this.setArrow("name")} />
                  </th>
                  <th onClick={() => this.onSort("lastName", users)} className="curser-pointer width-15 header-sticky">
                    Last Name
                    <span className={this.setArrow("lastName")} />
                  </th>
                  <th onClick={() => this.onSort("company", users)} className="curser-pointer width-15 header-sticky">
                    Company
                    <span className={this.setArrow("company")} />
                  </th>
                  <th onClick={() => this.onSort("role", users)} className="curser-pointer width-8 header-sticky">
                    Role
                    <span className={this.setArrow("role")} />
                  </th>
                  <th
                    onClick={() => this.onSort("userEnable", users)}
                    className="text-center curser-pointer width-8 header-sticky"
                  >
                    Status
                    <span className={this.setArrow("userEnable")} />
                  </th>
                  <th className="text-center width-8 header-sticky">Action</th>
                </tr>
              </thead>
              {loading ? (
                <tbody>
                  <tr>
                    <td colSpan="6" aria-label="td">
                      <Spinner />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {userList.length ? (
                    userList.map((user) => (
                      <tr key={user.userId}>
                        <td className="width-30">
                          <div className="cell-mx-width" data-tip={user.email}>
                            {user.email}
                          </div>
                          <ReactTooltip place="bottom" multiline type="light" effect="solid" />
                        </td>
                        <td className="width-15">
                          <div className="cell-mx-width" data-tip={user.name}>
                            {user.name}
                          </div>
                        </td>
                        <td className="width-15">
                          <div className="cell-mx-width" data-tip={user.lastName}>
                            {user.lastName}
                          </div>
                        </td>
                        <td className="width-15">
                          <div className="cell-mx-width" data-tip={user.company}>
                            {user.company}
                          </div>
                        </td>
                        <td className="width-8">
                          <div className="capitilize-column">{user.role}</div>
                        </td>
                        <td className="text-center width-8">
                          {user.userEnable ? (
                            <span className="badge badge-success">Enabled</span>
                          ) : (
                            <span className="badge badge-danger">Disable</span>
                          )}
                        </td>
                        <td className="text-center width-8">
                          <Dropdown className="ml-auto" container="body">
                            <Dropdown.Toggle as="a" class="nav-link pr-0 leading-none">
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                                aria-label="profile"
                                className="text-gray"
                              >
                                <i className="fa fa-ellipsis-h xx-large-icon" />
                              </a>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-right user-list-dropdown">
                              <Link
                                to={`/users/edit/${encodeURIComponent(user.email)}`}
                                class="btn-link-dropdown dropdown-item"
                              >
                                Edit
                              </Link>
                              {userEmail !== user.email ? <Dropdown.Divider /> : null}
                              {userEmail !== user.email ? (
                                <Link
                                  to="/users"
                                  href="#"
                                  onClick={() => this.openConfirmationModal(user)}
                                  class="btn-link-dropdown dropdown-item"
                                >
                                  {user.userEnable ? "Disable" : "Enable"}
                                </Link>
                              ) : null}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="text-center py-5">
                          <div className="mb-5">
                            <i className="fa fa-2x fa-user text-primary" />
                          </div>
                          <h3 className="text-muted mb-3">No Data Found</h3>
                          <p className="h6 text-muted font-weight-light">Please try with different keyword</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>

          {users.length ? (
            <div className="card-footer">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end mb-0">
                  <li>
                    <Select
                      onChange={this.pageChange}
                      options={commonUtiles.getPageSizes()}
                      name="role"
                      className="select-sdd mr-3"
                      autoFocus={false}
                      isSearchable={false}
                      value={pageSize}
                      styles={commonUtiles.getDropDownStyleForPage()}
                    />
                  </li>
                  {isPrevBtnActive === "disabled" ? (
                    <li className="page-item disabled">
                      <span className="page-link">Previous</span>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a href="#" className="page-link" onClick={this.btnPrevClick}>
                        Previous
                      </a>
                    </li>
                  )}
                  {isNextBtnActive === "disabled" ? (
                    <li className="page-item disabled">
                      <span className="page-link">Next</span>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a href="#" className="page-link" onClick={this.btnNextClick}>
                        Next
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
export default UserList;
